import React from 'react'
import axios from '../../node_modules/axios/index'
import { BASE_URL } from 'config'
import { getBearerToken } from './api'

export const resetFormErrors = (formValues) => {
    for (const [key, value] of Object.entries(formValues)) {
        value.error = false
        value.helperText = ""
    }
}

export const validateFormValues = (formValues) => {
    var error = true
    for (const [key, value] of Object.entries(formValues)) {
        if (value.validation !== undefined && !value.validation(value.value)) {
            error = false
        }
    }
    return error
}

export const resetFormValues = (formValues, setFormValues) => {
    for (const [key, value] of Object.entries(formValues)) {
        setFormValues((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                value: "",
                disabled: false
            },
        }));
    }
}

export const setToDisabled = (formValues, setFormValues, user, disabled) => {
    for (const [key, value] of Object.entries(formValues)) {
        setFormValues((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                value: (user[key] !== null && typeof user[key] === "object") ? user[key].id : user[key],
                disabled: disabled
            },
        }));
    }
}

export const generateUUID = () => {
    const { v4: uuidv4 } = require('uuid');
    return uuidv4();
}

export const logout = (body) => {
    if (body === undefined) {
        body = {
            token: (getBearerToken() === null || getBearerToken() === undefined) ? "" : getBearerToken()
        }
    }
    axios.post(`${BASE_URL}/utilisateurs/logout`, body).then((response) => {
        localStorage.clear()
        window.location.href = "/auth/login"
    }
    ).catch((error) => {
        window.location.href = "/auth/login"
    }
    )
}

const react_utilities = () => {
    return (
        <div>react_utilities</div>
    )
}

export default react_utilities