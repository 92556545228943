import React from 'react'
import { Button, ButtonGroup, Dialog, DialogContent } from '../../../node_modules/@mui/material/index'

const ConfirmDialog = ({dialogOpen,handleCloseDialog,message,handleConfirm}) => {
    return (
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogContent>
                <h3 color="initial">
                    {message}
                </h3>
                <ButtonGroup className="button-group">
                    <Button onClick={handleConfirm} variant="contained" color="error">
                        Confirm
                    </Button>
                    <Button onClick={handleCloseDialog} variant="contained" color="primary">
                        Cancel
                    </Button>
                </ButtonGroup>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialog