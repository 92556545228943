import { logout } from "./react_utilities";

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// done
export const getRowClassName = (params) => {
    // Ajouter your custom logic to determine the class name based on row data
    // For example, you can alternate row colors or apply other styling
    return params.id % 2 === 0 ? 'even-row' : 'odd-row';
};

export const lowerCaseFirstLetter = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

export const updatePageTitles = (title) => {
    const elements = document.getElementsByClassName("page-title-element");
    console.log(elements);
    for (let i = 0; i < elements.length; i++) {
        elements[i].innerHTML = title;
    }
}

export const formatMoney = (amount) => {
    let amountString =  amount.toLocaleString("en-US", { style: "currency", currency: "MGA" });
    // remove 4 first chars
    return amountString.slice(4)+" Ar";
}

export const jsDateToString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export const getUserFirstNameAndLastName = () => {
    let user = localStorage.getItem("user");
    if (user === null) {
        logout();
    }
    try {
        user = atob(user);
    } catch (error) {
        logout();
    }
    user = JSON.parse(user);
    return `${user.firstname} ${user.lastname}`;
}