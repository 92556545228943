// project import
import pages from './pages';
import dashboard from './dashboard';
import transactions from './transactions';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [/*dashboard,*/ pages, transactions]
};

export default menuItems;
