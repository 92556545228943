// assets
import { UserOutlined, ApiOutlined, BookOutlined } from '@ant-design/icons';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MoneyIcon from '@mui/icons-material/Money';
// icons
const icons = {
  UserOutlined,
  ApiOutlined,
  BookOutlined,
  MoneyIcon
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'management',
  title: 'Management',
  type: 'group',
  children: [
    {
      id: 'administrators',
      title: 'Administrateurs',
      type: 'item',
      url: '/admin/administrators',
      icon: AdminPanelSettingsIcon,
      target: true
    },{
      id: 'merchants',
      title: 'Marchands',
      type: 'item',
      url: '/admin/merchants',
      icon: icons.UserOutlined,
      target: true
    },
    {
      id: 'apis',
      title: 'Configurations API',
      type: 'item',
      url: '/admin/apis',
      icon: icons.ApiOutlined,
      target: true
    },
    {
      id: 'commission',
      title: 'Commission',
      type: 'item',
      url: '/admin/commission',
      icon: icons.MoneyIcon,
      target: true
    }
  ]
};

export default pages;
