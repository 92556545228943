import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Alert, Box, Button } from '../../../node_modules/@mui/material/index';
import { useEffect } from 'react';
import { getCall } from 'utilities/api';
import { getRowClassName, jsDateToString } from 'utilities/utilities';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import CustomInput from 'components/form/CustomInput';
import CustomDateInput from 'components/form/CustomDateInput';

const PendingTransaction = () => {
    // mandatory states
    const [selectedIds, setSelectedIds] = useState([]);
    const labelInfo = "Paiements traités"
    const title = "Paiements traités"
    const [showDelete, setShowDelete] = useState(false)
    const [pageSubmitResponse, setPageSubmitResponse] = useState(false)
    const [query, setQuery] = useState("")
    const [entities, setEntities] = useState({
        elements: [],
        count: 0,
        page: 0,
        pageSize: 0,
    })

    let rowCount = 5

    const [rowCountState, setRowCountState] = useState(rowCount);

    const [initialState, setInitialState] = useState({
        pagination: {
            paginationModel: { page: 0, pageSize: 5 },
        },
    });
    const formatEntities = (data) => {
        setInitialState(
            {
                pagination: {
                    paginationModel: { page: data.page, pageSize: data.pageSize },
                },
            }
        )
        setRowCountState(data.count)
    }

    const getProcessedClientPayment = (page, pageSize, localQuery) => {
        getCall(`/processed-client-payment/all?page=${page}&size=${pageSize}&${localQuery}`, setEntities, setPageSubmitResponse, undefined, formatEntities)
    }

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
        getProcessedClientPayment(initialState.pagination.paginationModel.page, initialState.pagination.paginationModel.pageSize)
    }, [])

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'clientName', headerName: 'Application', width: 130 },
        { field: 'paymentMethodName', headerName: 'Mode de paiement', width: 130 },
        { field: 'clientPaymentReference', headerName: 'Référence', width: 130 },
        { field: 'amount', headerName: 'Volume', width: 130 },
        { field: 'fee', headerName: 'Frais', width: 130 },
        { field: 'dueDate', headerName: 'Échéance', width: 130 },
        {
            field: 'details',
            headerName: 'Plus',
            width: 150,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); navigateToDetails(params.id) }}>Détails</Button>
            ),
        },
    ];

    const navigate = useNavigate()

    const navigateToDetails = (id) => {
        navigate(`/admin/payment-details/${id}`)
    }

    // not to be touched anymore
    const [searchFormValues, setSearchFormValues] = useState({
        clientName: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'clientName')
            }
        },
        minAmount: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'minAmount')
            }
        },
        maxAmount: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'maxAmount')
            }
        },
        minDueDate: {
            value: "",
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'minDueDate')
            }
        },
        maxDueDate: {
            value: "",
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'maxDueDate')
            }
        },
    })

    const handleSearchFormChange = (e, label) => {
        console.log(label)
        const target = e.target
        if (target !== undefined) {
            const { name, value } = e.target;
            setSearchFormValues((prevState) => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value,
                },
            }));
        } else {
            let newDate = e;
            const date = new Date(newDate.$d)
            const value = jsDateToString(date);
            setSearchFormValues((prevState) => ({
                ...prevState,
                [label]: {
                    ...prevState[label],
                    value,
                },
            }));
        }

    }

    const handleSearch = () => {
        let localQuery = buildSearchQuery(searchFormValues)
        console.log(localQuery)
        getProcessedClientPayment(initialState.pagination.paginationModel.page, initialState.pagination.paginationModel.pageSize, localQuery)
        setQuery(localQuery)
    }

    const buildSearchQuery = (searchFormValues) => {
        let localQuery = "";
        if (searchFormValues.clientName.value !== "") {
            localQuery += "clientName=" + searchFormValues.clientName.value + "&"
        }
        if (searchFormValues.minAmount.value !== "") {
            localQuery += "minAmount=" + searchFormValues.minAmount.value + "&"
        }
        if (searchFormValues.maxAmount.value !== "") {
            localQuery += "maxAmount=" + searchFormValues.maxAmount.value + "&"
        }
        if (searchFormValues.minDueDate.value !== "") {
            localQuery += "minDueDate=" + searchFormValues.minDueDate.value + "&"
        }
        if (searchFormValues.maxDueDate.value !== "") {
            localQuery += "maxDueDate=" + searchFormValues.maxDueDate.value + "&"
        }
        return localQuery;
    }

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    '& .MuiFormControl-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                style={{ padding: "35px", backgroundColor: "white" }}
            >
                <h3>Recherche avancé</h3>
                <div>
                    <CustomInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Nom du client"} label="clientName" required={false} />
                    {/* payment method */}
                    <CustomInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Montant minimum"} label="minAmount" required={false} />
                    <CustomInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Montant maximum"} label="maxAmount" required={false} />
                    <CustomDateInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Échéance minimum"} label="minDueDate" required={false} noDefault={true} />
                    <CustomDateInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Échéance maximum"} label="maxDueDate" required={false} noDefault={true} />
                </div>
                <br />
                <Button onClick={handleSearch} variant="contained" color="primary">
                    Valider
                </Button>
            </Box>
            <br />
            <div style={{ height: '70vh', width: '100%', backgroundColor: 'white', paddingTop: "10px" }}>
                {/* add and delete button group */}
                {
                    pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
                }
                {/* datatable using datagrid */}
                <h3 style={{ textAlign: "center" }}>{title}</h3>
                <DataGrid
                    rows={entities?.elements}
                    columns={columns}
                    initialState={initialState}
                    pageSizeOptions={[5, 10]}
                    getRowClassName={getRowClassName}
                    paginationMode="server"
                    rowCount={rowCountState}
                    onPaginationModelChange={(params) => {
                        console.log(params)
                        setInitialState({
                            pagination: {
                                paginationModel: { page: params.page, pageSize: params.pageSize },
                            },
                        })
                        getProcessedClientPayment(params.page, params.pageSize)
                    }}
                />
            </div>
        </>
    )
}

export default PendingTransaction