import { BASE_URL } from "config";
import axios from "../../node_modules/axios/index";
import { isArray } from "lodash";

export const getBearerToken = () => {
    const token = localStorage.getItem('token');
    // if(token===null){
    //     // window.location.href = "/auth/login"
    // }
    return token;
}

export const getHeaders = () => {
    const headers = {
        'Authorization': 'Bearer ' + getBearerToken(),
    };
    return { headers }
}

const handleAuthorization = (error) => {
    if (error.response.status === 403) {
        window.location.href = "/auth/not-found"
    }else{
        window.location.href = "/auth/login"
    }
}

export const getCall = (url, setData, setPageSubmitResponse, format, customCall) => {
    axios.get(`${BASE_URL + url}`, getHeaders()).then((response) => {
        setPageSubmitResponse(false)
        // console.log(response.data.data)
        format !== undefined ? setData(format(response.data.data)) : setData(response.data.data)
        if (customCall !== undefined) {
            customCall(response.data.data)
        }
    }).catch((error) => {
        // setSubmitResponse({ severity: "error", message: error.response.data.message })
        console.log(error)
    }
    )
}

export const putCall = (url, currentUser, setSubmitResponse, getCallBack) => {
    axios.put(`${BASE_URL + url + "/" + currentUser.id}`, currentUser, getHeaders()).then((response) => {
        setSubmitResponse({ severity: "success", message: "User updated successfully" })
    }
    ).then(() => {
        getCallBack && getCallBack()
    }).catch((error) => {
        setSubmitResponse({ severity: "error", message: error.response.data.message })
    }
    )
}

export const postCall = (url, user, resetFormValues, formValues, setFormValues, setSubmitResponse, handleCloseAddDialog, getCallBack) => {
    axios.post(`${BASE_URL + url}`, user, getHeaders()).then((response) => {
        resetFormValues(formValues, setFormValues)
        setSubmitResponse({ severity: "success", message: "User added successfully" })
        setTimeout(() => {
            handleCloseAddDialog()
        }, 3000)
    }).then(() => {
        getCallBack && getCallBack()
    }).catch((error) => {
        setSubmitResponse({ severity: "error", message: error.response.data.message })
    }
    )
}