import React from 'react'
import { TextField } from '../../../node_modules/@mui/material/index'
import { lowerCaseFirstLetter } from 'utilities/utilities'
import { generateUUID } from 'utilities/react_utilities'

const CustomInput = ({ formValues, handleChange, label, required, title}) => {
    return (
        <TextField
        // unique key to force re-render on change of formValues
            disabled={formValues[lowerCaseFirstLetter(label)].disabled}
            required={required}
            id={lowerCaseFirstLetter(label)}
            name={lowerCaseFirstLetter(label)}
            label={title!==undefined ? title:label}
            defaultValue={formValues[lowerCaseFirstLetter(label)].value}
            onChange={handleChange}
            error={formValues[lowerCaseFirstLetter(label)].error}
            helperText={formValues[lowerCaseFirstLetter(label)].error && formValues[lowerCaseFirstLetter(label)].helperText}
        // force focus on change
        />
    )
}

export default CustomInput