import React from 'react'
import { useParams } from '../../../node_modules/react-router-dom/dist/index'
import ClientApiPaymentMethodInformation from './ClientApiPaymentMethodInformation'
import PaymentMethodMatch from './PaymentMethodMatch'

const ClientAppInformations = () => {
    const { clientId } = useParams()
  return (
    <>
        <ClientApiPaymentMethodInformation clientId={clientId}/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <PaymentMethodMatch clientId={clientId}/>
    </>
  )
}

export default ClientAppInformations