import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Typography } from '../../../node_modules/@mui/material/index';
import { jsDateToString, lowerCaseFirstLetter } from 'utilities/utilities';
import { useEffect } from 'react';
const CustomDateInput = ({ formValues, handleChange, label, noDefault, title }) => {

    const formattedDate = jsDateToString(new Date());

    const [value, setValue] = React.useState(dayjs(formattedDate));

    useEffect(() => {
        handleChange(value);
    }, [])


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={title!==undefined ? title:label}
                defaultValue={noDefault!==undefined ? undefined:value }
                onChange={(newValue) => handleChange(newValue,label)}
                error={false}
                format="DD/MM/YYYY"
                minDate={noDefault!==undefined ? undefined: dayjs(formattedDate)}
            />
            <Typography variant="body2" color={formValues[lowerCaseFirstLetter(label)].error ? 'error' : 'initial'}>
                {formValues[lowerCaseFirstLetter(label)].helperText}
            </Typography>
        </LocalizationProvider>
    );
}

export default CustomDateInput