export const validateNotNullInput = (value,setFormValues,fieldName, title) => {
    if (value === null || value === "") {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: true,
                helperText: title!==undefined ? title: fieldName+" is required"
            },
        }));
        return false;
    } else {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: false,
                helperText: ""
            },
        }));
        return true;
    }
}

export const validateEmailInput = (value,setFormValues,fieldName, title) => {
    const emailRegex=/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!emailRegex.test(value)) {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: true,
                helperText: title!==undefined ? title : fieldName+" is not valid"
            },
        }));
        return false;
    } else {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: false,
                helperText: ""
            },
        }));
        return true;
    }
}

export const validatePhoneNumberInput = (value,setFormValues,fieldName, title) => {
    if(value===null || value===""){
        return true;
    }
    // start with 032, 033, 034, 038 and is 10 digits long
    const phoneRegex=/^(032|033|034|038)[0-9]{7}$/;
    if (!phoneRegex.test(value)) {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: true,
                helperText: title!==undefined ? title : fieldName+" is not valid"
            },
        }));
        return false;
    } else {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: false,
                helperText: ""
            },
        }));
        return true;
    }
}

export const validatePanInput = (value,setFormValues,fieldName, title) => {
    if(value===null || value===""){
        return true;
    }
    // 14 to 19 digits long
    const panRegex=/^[0-9]{14,19}$/;
    if (!panRegex.test(value)) {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: true,
                helperText: title!==undefined ? title : fieldName+" is not valid"
            },
        }));
        return false;
    } else {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: false,
                helperText: ""
            },
        }));
        return true;
    }
}

export const validateYearInput = (value,setFormValues,fieldName, title) => {
    if(value===null || value===""){
        return true;
    }
    // 2 digits long between 20 and 40
    const yearRegex=/^(20|21|22|23|24|25|26|27|28|29|30|31|32|33|34)$/;
    if (!yearRegex.test(value)) {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: true,
                helperText: title!==undefined ? title : fieldName+" is not valid"
            },
        }));
        return false;
    } else {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: false,
                helperText: ""
            },
        }));
        return true;
    }
}

export const validateMonthInput = (value,setFormValues,fieldName, title) => {
    if(value===null || value===""){
        return true;
    }
    // 1 to 12 digits long
    const monthRegex=/^(0?[1-9]|1[012])$/;
    if (!monthRegex.test(value)) {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: true,
                helperText: title!==undefined ? title : fieldName+" is not valid"
            },
        }));
        return false;
    } else {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: false,
                helperText: ""
            },
        }));
        return true;
    }
}

export const validateSecurityCodeInput = (value,setFormValues,fieldName, title) => {
    if(value===null || value===""){
        return true;
    }
    // three digits long
    const securityCodeRegex=/^[0-9]{3}$/;
    if (!securityCodeRegex.test(value)) {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: true,
                helperText: title!==undefined ? title : fieldName+" is not valid"
            },
        }));
        return false;
    } else {
        setFormValues((prevState) => ({
            ...prevState,
            [fieldName]: {
                ...prevState[fieldName],
                error: false,
                helperText: ""
            },
        }));
        return true;
    }
}
