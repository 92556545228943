import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Alert, Box } from '../../../node_modules/@mui/material/index';
import axios from '../../../node_modules/axios/index';
import { useEffect } from 'react';
import { BASE_URL } from 'config';
import { getCall, getHeaders, postCall, putCall } from 'utilities/api';
import { validateEmailInput, validateNotNullInput } from 'utilities/validation';
import CustomSelect from 'components/form/CustomSelect';
import CustomInput from 'components/form/CustomInput';
import CustomEmailInput from 'components/form/CustomEmailInput';
import AddDeleteButtonGroup from 'components/button/AddDeleteButtonGroup';
import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { getRowClassName, updatePageTitles } from 'utilities/utilities';
import { resetFormErrors, resetFormValues, validateFormValues, setToDisabled } from 'utilities/react_utilities';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';

const User = () => {
    // mandatory states
    const [selectedIds, setSelectedIds] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [formTitle, setFormTitle] = useState("Ajouter an admin")
    const [showDelete, setShowDelete] = useState(false)
    const [updateButton, setUpdateButton] = useState(true)
    const [submitResponse, setSubmitResponse] = useState(false)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [pageSubmitResponse, setPageSubmitResponse] = useState(false)

    // user defined state
    const [users, setUsers] = useState([])
    const [usersPageData, setUsersPageData] = useState([])

    // mandatory form data
    const [formValues, setFormValues] = useState({
        lastname: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'lastname')
            }
        },
        firstname: {
            value: '',
            error: false,
            helperText: ''
        },
        email: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateEmailInput(value, setFormValues, 'email')
            }
        },
        gender: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'gender')
            }
        },
        city: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'city')
            }
        },
        activityArea: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'activityArea', 'Activity area')
            }
        },
        address: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'address')
            }
        }
    })

    const getUsers = () => {
        getCall("/utilisateurs/all-user", setUsers, setPageSubmitResponse, undefined)
      }

    useEffect(() => {
        updatePageTitles("Merchant")
        getCall("/utilisateurs/all-user", setUsers, setPageSubmitResponse, undefined)
        getCall("/users-page-data/all", setUsersPageData, setPageSubmitResponse, undefined)
    }, [])

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'firstname', headerName: 'Prénom', width: 130 },
        { field: 'lastname', headerName: 'Nom', width: 130 },
        {
            field: 'genderName',
            headerName: 'Gendre',
            width: 90,
        },
        {
            field: 'permissionName',
            headerName: 'Permission',
            width: 150,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, true) }}>Modifier</Button>
            ),
        },
        // see more link with params.id
        {
            field: 'more',
            headerName: 'Plus',
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" color="primary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, false) }}>Détail</Button>
            ),
        },
        {
            field: 'applications',
            headerName: 'Applications',
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" color="info" onClick={(e) => { e.stopPropagation(); navigateToApplications(params.id) }}>Applications</Button>
            )
        }
    ];

    const navigate = useNavigate()

    const navigateToApplications = (id) => {
        navigate(`/admin/user/${id}/applications`)
    }

    const buildEntity = (formValues) => {
        return {
            firstname: formValues.firstname.value,
            lastname: formValues.lastname.value,
            email: formValues.email.value,
            address: formValues.address.value,
            city: {
                id: formValues.city.value
            },
            activityArea: {
                id: formValues.activityArea.value
            },
            gender: {
                id: formValues.gender.value
            },
            permission: {
                id: 1
            }
        }
    }


    const handleAddUser = (e) => {
        // execute validation in formValues
        const success = validateFormValues(formValues)
        if (success) {
            const user = buildEntity(formValues)
            user.password = "iboniaDefault1234!"
            postCall("/utilisateurs", user, resetFormValues, formValues, setFormValues, setSubmitResponse, handleCloseAddDialog,getUsers)
        }
    }

    const handleUpdateUser = (e) => {
        const success = validateFormValues(formValues)
        if (success) {
            let currentUser = buildEntity(formValues)
            currentUser.id = currentUserId
            putCall("/utilisateurs", currentUser, setSubmitResponse,getUsers)
        }
    }

    const handleUpdateClick = (params, update) => {
        resetFormErrors(formValues)
        setSubmitResponse(false)
        // mock a fake user
        axios.get(`${BASE_URL}/utilisateurs/${params.id}`, getHeaders()).then((response) => {
            const user = response.data.data
            setCurrentUserId(user.id)
            if (!update) {
                setUpdateButton(false)
                // set formValues default value
                setToDisabled(formValues, setFormValues, user, true)
            } else {
                setUpdateButton(true)
                // set formValues default value
                setToDisabled(formValues, setFormValues, user, false)
            }
            setFormTitle(update ? "Modifier an user" : "Info of an user")
            setAddDialogOpen(true);
        }
        ).catch((error) => {
            setSubmitResponse({ severity: "error", message: error.response.data.message })
        }
        )
    };

    const handleConfirmDelete = () => {
        // Perform delete logic
        axios.post(`${BASE_URL}/utilisateurs/multiple-delete`, selectedIds, getHeaders()).then((response) => {
            setPageSubmitResponse({ severity: "success", message: "Users deleted successfully" })
            getUsers()
        }
        ).catch((error) => {
            setPageSubmitResponse({ severity: "error", message: error.response.data.message })
        }
        ).finally(() => {
            setDialogOpen(false);
        }).then(() => {
            setTimeout(() => {
                setPageSubmitResponse(false)
            }, 3000)
        })
        // Close the dialog
    };

    // not to be touched anymore

    // done
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
            },
        }));
    }

    // done
    const handleSelectionChange = (selectionModel) => {
        // replace existing selectedIds with selectionModel
        selectionModel.length !== 0 ? setShowDelete(true) : setShowDelete(false)
        setSelectedIds(selectionModel);
    };

    // done
    const handleDeleteClick = () => {
        setDialogOpen(true); // Open the dialog
    };

    // done
    const handleCloseDialog = () => {
        // Close the dialog without performing delete
        setDialogOpen(false);
    };

    // done
    const handleAddClick = () => {
        setSubmitResponse(false)
        setUpdateButton(true)
        setFormTitle("Ajouter un marchand")
        // set formValues error to false
        resetFormErrors(formValues)
        resetFormValues(formValues, setFormValues)
        setAddDialogOpen(true);
    };

    // done
    const handleCloseAddDialog = () => {
        setAddDialogOpen(false);
    };

    return (
        <div style={{ height: '70vh', width: '100%', backgroundColor: 'white' }}>
            {/* add and delete button group */}
            <AddDeleteButtonGroup handleAddClick={handleAddClick} handleDeleteClick={handleDeleteClick} showDelete={showDelete} />
            {
                pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
            }
            {/* datatable using datagrid */}
            <h3 style={{ textAlign: "center" }}>Marchands</h3>
            <DataGrid
                rows={users}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={getRowClassName}
                onRowSelectionModelChange={(item) => handleSelectionChange(item)}
                checkboxSelection
            />
            {/* dialog for delete items */}
            <ConfirmDialog dialogOpen={dialogOpen} handleCloseDialog={handleCloseDialog} message={`Are you sure you want to delete those ${selectedIds.length} item(s)?`} handleConfirm={handleConfirmDelete} />
            {/* Ajouter dialog */}
            <Dialog open={addDialogOpen} onClose={handleCloseAddDialog}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        '& .MuiFormControl-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    style={{ padding: "35px" }}
                >
                    {
                        submitResponse && <Alert severity={submitResponse.severity}>{submitResponse.message}</Alert>
                    }
                    <h2 id="user-form">{formTitle}</h2>
                    <div>
                        <CustomInput formValues={formValues} handleChange={handleChange} label={"Firstname"} required={false} />
                        <CustomInput formValues={formValues} handleChange={handleChange} title={"Lastname"} label={"Lastname"} required={true} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CustomEmailInput formValues={formValues} handleChange={handleChange} label={"Email"} required={true} />
                        <CustomSelect formValues={formValues} handleChange={handleChange} items={usersPageData.genders} label={"Gender"} />
                    </div>
                    <div>
                        <CustomSelect formValues={formValues} handleChange={handleChange} items={usersPageData.cities} label={"City"} />
                        <CustomSelect formValues={formValues} handleChange={handleChange} items={usersPageData.activityAreas} title={"Activity area"} label={"activityArea"} />
                    </div>
                    <div>
                        <CustomInput formValues={formValues} handleChange={handleChange} label={"Address"} required={true} />
                    </div>
                    <br />
                    <div style={{ width: "100%" }}>
                        {
                            updateButton && <Button onClick={formTitle === "Ajouter un marchand" ? handleAddUser : handleUpdateUser} variant="contained" color="primary">
                                {formTitle === "Ajouter un marchand" ? "Ajouter" : "Modifier"}
                            </Button>
                        }
                    </div>
                </Box>
            </Dialog>
        </div>
    )
}

export default User