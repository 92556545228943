// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import "./App.css"
import axios from '../node_modules/axios/index';
import { BASE_URL } from 'config';
import { getBearerToken } from 'utilities/api';
import { useEffect } from 'react';
import { set } from 'lodash';
import { useLocation } from '../node_modules/react-router-dom/dist/index';
import { logout } from 'utilities/react_utilities';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {

  const location=useLocation()

  const timeOut = () => {
    const body = {
      token: (getBearerToken() === null || getBearerToken() === undefined) ? "" : getBearerToken()
    }
    axios.post(`${BASE_URL}/utilisateurs/is-logged-in`, body).then((response) => {
      const isLoggedIn = response.data.data
      console.log("isLoggedIn", isLoggedIn)
      if (!isLoggedIn && location.pathname!=='/auth/login') {
        console.log("logout")
        logout(body)
      }
    }
    ).catch((error) => {
      console.log("error", error)
      // window.location.href = "/auth/login"
    }
    )
  }

  useEffect(() => {
    timeOut()
  }, [])

  setInterval(() => {
    timeOut()
  }, 2500000)


  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
}
export default App;
