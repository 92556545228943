import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';

import './SelectionCard.css'

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const SelectionCard = ({ title,paymentMethod,handleClick }) => {
    return(
    <MainCard contentSX={{ p: 2.25 }} style={(paymentMethod?.selected===true)?{backgroundColor:"rgb(224, 238, 253)"}:{backgroundColor:"rgb(255, 255, 255)"}} className="custom-selection-card" onClick={()=>{
        handleClick(paymentMethod.id)
    }}>
        <Stack spacing={0.5}>
            <Grid container alignItems="center" justifyContent="space-around">
                <Grid item>
                    <Typography variant="h4" color="inherit">
                        {paymentMethod.name}
                    </Typography>
                </Grid>
                <Grid item style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <img src={paymentMethod.logo} alt={paymentMethod.alt} />
                </Grid>
            </Grid>
        </Stack>
        <Box sx={{ pt: 2.25 }} style={{ textAlign: "center" }}>
            <Typography variant="caption" color="textSecondary">
                Par {' '}
                <Typography component="span" variant="caption" sx={{ color: `primary.main` }}>
                    PAPI
                </Typography>{' '}
                @Ibonia
            </Typography>
        </Box>
    </MainCard>
);}

SelectionCard.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

SelectionCard.defaultProps = {
    color: 'primary'
};

export default SelectionCard;
