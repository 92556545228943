import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const NotFoundPage = Loadable(lazy(() => import('pages/extra-pages/NotFoundPage')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/auth',
  element: <MinimalLayout />,
  children: [
    {
      path: '',
      element: <Navigate to="/not-found" replace />
    },
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path:"*",
      element: <Navigate to="/not-found" replace />
    }
  ]
};
export default LoginRoutes;
