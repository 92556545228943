import React from 'react';
import { Navigate } from 'react-router-dom';

const Authorization = ({ condition, redirectPath, component: Component }) => {
  if (condition) {
    return <Component />;
  } else {
    return <Navigate to={redirectPath} replace />;
  }
};

export default Authorization;