import { getHeaders } from "utilities/api";
import { Alert, Box, Button, Dialog } from "../../../node_modules/@mui/material/index";
import axios from "../../../node_modules/axios/index";
import { useState } from "react";
import { BASE_URL } from "config";

const PaymentPopIn = ({ show, setShow, totalAmount, elementsId }) => {

    const [message, setMessage] = useState("")
    
    const handleCloseDialog = () => {
        setShow(false)
    }

    const handleMultiplePayment = async () => {
        console.log("Multiple payment", elementsId)
        const response = await axios.post(`${BASE_URL}/all-client-payment-detail/set-payment-to-paid`, elementsId , getHeaders())
        if(response.status === 200){
            setMessage(<Alert severity="success">Les paiements ont été enregistrés avec succès</Alert>)
        }else{
            setMessage(<Alert severity="error">Erreur lors de l'enregistrement des paiements</Alert>)
        }
    }

    return (
        <>
            {
                <Dialog open={show} onClose={handleCloseDialog}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                            '& .MuiFormControl-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        style={{ padding: "35px" }}
                    >
                        <h3>Enregistrement les paiements</h3>
                        <p>Nombre de paiements à enregistrer : {elementsId.length} paiement(s) </p>
                        <p>Montant total : {totalAmount}</p>
                        <Button onClick={handleMultiplePayment} variant="contained" color="primary">
                            Valider
                        </Button>
                        <br/>
                        <br/>
                        {message}
                    </Box>
                </Dialog>
            }
        </>
    );
}

export default PaymentPopIn;