import React from 'react'
import { Button, ButtonGroup } from '../../../node_modules/@mui/material/index'

const AddDeleteButtonGroup = ({ handleAddClick, handleDeleteClick, showDelete }) => {
    return (
        <div className="button-container">
            <ButtonGroup className="button-group">
                <Button variant="contained" color="primary" className="crud-button" onClick={handleAddClick}>
                    Ajouter
                </Button>
                {
                    showDelete && <Button variant="contained" color="error" className="crud-button" onClick={handleDeleteClick}>
                        Supprimer
                    </Button>
                }
            </ButtonGroup>
        </div>
    )
}

export default AddDeleteButtonGroup