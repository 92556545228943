import React from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '../../../node_modules/@mui/material/index'
import { capitalizeFirstLetter, lowerCaseFirstLetter } from 'utilities/utilities'

const CustomSelect = ({ formValues, handleChange, items, label, title }) => {
    console.log(items)
    return (
        <FormControl required>
            <InputLabel id="demo-simple-select-label" error={formValues[lowerCaseFirstLetter(label)].error}>{title!==undefined?title:label}</InputLabel>
            <Select
                disabled={formValues[lowerCaseFirstLetter(label)].disabled}
                label={title!==undefined?title:label}
                name={lowerCaseFirstLetter(label)}
                value={formValues[lowerCaseFirstLetter(label)].value}
                // onChange={/* onChange handler */}
                onChange={handleChange}
                error={formValues[lowerCaseFirstLetter(label)].error}
            >
                {
                    items.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.id} selected={true}>{item.name ? item.name : item.alias}</MenuItem>
                        )
                    })
                }
            </Select>
            <Typography variant="body2" style={{marginLeft:'14px'}} color={formValues[lowerCaseFirstLetter(label)].error ? 'error' : 'initial'}>
                {formValues[lowerCaseFirstLetter(label)].helperText}
            </Typography>
        </FormControl>
    )
}

export default CustomSelect