import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Alert, Box, Button, Dialog, Typography } from '../../../node_modules/@mui/material/index';
import { useEffect } from 'react';
import { getCall, getHeaders, putCall } from 'utilities/api';
import { getRowClassName, jsDateToString } from 'utilities/utilities';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import CustomDateInput from 'components/form/CustomDateInput';
import { resetFormValues, validateFormValues } from 'utilities/react_utilities';
import { validateNotNullInput } from 'utilities/validation';
import { get, set } from 'lodash';
import axios from '../../../node_modules/axios/index';
import { BASE_URL } from 'config';
import CustomInput from 'components/form/CustomInput';

const PendingTransaction = () => {
    // mandatory states
    const [selectedId, setSelectedId] = useState(null);
    const title = "Paiements"
    const [pageSubmitResponse, setPageSubmitResponse] = useState(false)
    const [postponeDialogOpen, setPostponeDialogOpen] = useState(false);
    const [processDialogOpen, setProcessDialogOpen] = useState(false);
    const [query, setQuery] = useState("")
    const [entities, setEntities] = useState({
        elements: [],
        count: 0,
        page: 0,
        pageSize: 0,
    })
    const [submitResponse, setSubmitResponse] = useState(false)

    const [proccessPaymentPage, setProccessPaymentPage] = useState({})

    const [initialState, setInitialState] = useState({
        pagination: {
            paginationModel: { page: 0, pageSize: 10 },
        },
    })

    const [postponeFormValues, setFormValues] = useState({
        dueDate: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'dueDate')
            }
        },
    })

    const [processFormValues, setProcessFormValues] = useState({
        operatorReference: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setProcessFormValues, 'operatorReference')
            }
        },
    })

    const formatEntities = (data) => {
        setInitialState(
            {
                pagination: {
                    paginationModel: { page: data.page, pageSize: data.pageSize },
                },
            }
        )
        setRowCountState(data.count)
    }

    const getPendingClientPayment = (page, size, localQuery) => {
        getCall(`/pending-client-payment/all?page=${page}&size=${size}&${localQuery}`, setEntities, setPageSubmitResponse, undefined, formatEntities)
    }

    let rowCount = 5

    const [rowCountState, setRowCountState] = useState(rowCount);
    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
        getPendingClientPayment(initialState.pagination.paginationModel.page, initialState.pagination.paginationModel.pageSize,query)
    }, [])

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'clientName', headerName: 'Application', width: 130 },
        { field: 'paymentMethodName', headerName: 'Mode de paiement', width: 130 },
        { field: 'clientPaymentReference', headerName: 'Référence', width: 130 },
        { field: 'amount', headerName: 'Volume', width: 130 },
        { field: 'fee', headerName: 'Frais', width: 130 },
        { field: 'dueDate', headerName: 'Échéance', width: 130 },
        {
            field: 'details',
            headerName: 'Plus',
            width: 150,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); navigateToDetails(params.id) }}>Détails</Button>
            ),
        },
        {
            field: 'postpone',
            headerName: 'Report',
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" color="info" onClick={(e) => { e.stopPropagation(); postpone(params.id) }}>Reporter</Button>
            ),
        },
        {
            field: 'process',
            headerName: 'Enregistrement',
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" color="success" onClick={(e) => { e.stopPropagation(); process(params.id) }}>Enregistrer</Button>
            ),
        },
    ];

    const navigate = useNavigate()

    const navigateToDetails = (id) => {
        navigate(`/admin/payment-details/${id}`)
    }

    const postpone = (id) => {
        setSelectedId(id)
        setSubmitResponse(false)
        setPostponeDialogOpen(true)
    }

    const process = (id) => {
        setSelectedId(id)
        setSubmitResponse(false)
        axios.get(`${BASE_URL}/process-payment-page/${id}/all`, getHeaders()).then((response) => {
            setProccessPaymentPage(response.data.data)
        }
        ).then(() => {
            setProcessDialogOpen(true)
        }).catch((error) => {
            console.log(error)
            setSubmitResponse({ severity: "error", message: error.response.data.message })
        }
        )
    }
    // not to be touched anymore

    const handleCloseDialog = () => {
        setPostponeDialogOpen(false);
        setProcessDialogOpen(false);
    };

    const handlePostponeDateChange = (newDate) => {
        // format date
        const date = new Date(newDate.$d)
        const value = jsDateToString(date);
        setFormValues((prevState) => ({
            ...prevState,
            dueDate: {
                ...prevState.dueDate,
                value,
            },
        }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProcessFormValues((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
            },
        }));
    }

    const buildPostponeEntity = (postponeFormValues) => {
        return {
            dueDate: postponeFormValues.dueDate.value,
        }
    }

    const buildProcessEntity = (processFormValues) => {
        return {
            operatorReference: processFormValues.operatorReference.value,
        }
    }

    const handlePostpone = () => {
        console.log(postponeFormValues)
        const success = validateFormValues(postponeFormValues)
        const entity = buildPostponeEntity(postponeFormValues)
        console.log(entity, selectedId)
        if (success) {
            axios.put(`${BASE_URL}/client-payments/postpone/${selectedId}/${entity.dueDate}`, entity, getHeaders()).then((response) => {
                setSubmitResponse({ severity: "success", message: "Payment postponed successfully" })
                getPendingClientPayment(initialState.pagination.paginationModel.page, initialState.pagination.paginationModel.pageSize,query)
                setTimeout(() => {
                    handleCloseDialog()
                }, 3000)
            })
        }
    }

    const handleProcess = () => {
        const success = validateFormValues(processFormValues)
        const entity = buildProcessEntity(processFormValues)
        console.log(entity, selectedId)
        if (success) {
            axios.put(`${BASE_URL}/client-payments/process/${selectedId}/${entity.operatorReference}`, entity, getHeaders()).then((response) => {
                setSubmitResponse({ severity: "success", message: "Payment processed successfully" })
                getPendingClientPayment(initialState.pagination.paginationModel.page, initialState.pagination.paginationModel.pageSize,query)
                setTimeout(() => {
                    handleCloseDialog()
                }, 3000)
            }).catch((error) => {
                console.log(error)
                setSubmitResponse({ severity: "error", message: error.response.data.message })
            })
        }
    }

    const [searchFormValues, setSearchFormValues] = useState({
        clientName: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'clientName')
            }
        },
        reference: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'reference')
            }
        },
        minAmount: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'minAmount')
            }
        },
        maxAmount: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'maxAmount')
            }
        },
        minDueDate: {
            value: "",
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'minDueDate')
            }
        },
        maxDueDate: {
            value: "",
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'maxDueDate')
            }
        },
    })

    const handleSearchFormChange = (e,label) => {
        console.log(label)
        const target = e.target
        if (target !== undefined) {
            const { name, value } = e.target;
            setSearchFormValues((prevState) => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value,
                },
            }));
        } else {
            let newDate = e;
            const date = new Date(newDate.$d)
            const value = jsDateToString(date);
            setSearchFormValues((prevState) => ({
                ...prevState,
                [label]: {
                    ...prevState[label],
                    value,
                },
            }));
        }

    }

    const handleSearch = () => {
        let localQuery = buildSearchQuery(searchFormValues)
        console.log(localQuery)
        getPendingClientPayment(initialState.pagination.paginationModel.page, initialState.pagination.paginationModel.pageSize, localQuery)
        setQuery(localQuery)
    }

    const buildSearchQuery = (searchFormValues) => {
        let localQuery="";
        if(searchFormValues.clientName.value!==""){
            localQuery+="clientName="+searchFormValues.clientName.value+"&"
        }
        if(searchFormValues.minAmount.value!==""){
            localQuery+="minAmount="+searchFormValues.minAmount.value+"&"
        }
        if(searchFormValues.maxAmount.value!==""){
            localQuery+="maxAmount="+searchFormValues.maxAmount.value+"&"
        }
        if(searchFormValues.minDueDate.value!==""){
            localQuery+="minDueDate="+searchFormValues.minDueDate.value+"&"
        }
        if(searchFormValues.maxDueDate.value!==""){
            localQuery+="maxDueDate="+searchFormValues.maxDueDate.value+"&"
        }
        if (searchFormValues.reference.value!=="") {
            localQuery+="clientPaymentReference="+searchFormValues.reference.value+"&"
        }

        return localQuery;
    }

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    '& .MuiFormControl-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                style={{ padding: "35px", backgroundColor: "white" }}
            >
                <h3>Recherche</h3>
                <div>
                    <CustomInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Nom du client"} label="clientName" required={false} />
                    <CustomInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Référence"} label="reference" required={false} />
                    {/* payment method */}
                    <CustomInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Montant minimum"} label="minAmount" required={false} />
                    <CustomInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Montant maximum"} label="maxAmount" required={false} />
                    <CustomDateInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Échéance minimum"} label="minDueDate" required={false} noDefault={true}/>
                    <CustomDateInput formValues={searchFormValues} handleChange={handleSearchFormChange} title={"Échéance maximum"} label="maxDueDate" required={false} noDefault={true}/>
                </div>
                <br />
                <Button onClick={handleSearch} variant="contained" color="primary">
                    Valider
                </Button>
            </Box>
            <br />
            <div style={{ height: '70vh', width: '100%', backgroundColor: 'white', paddingTop: "10px" }}>
                {/* add and delete button group */}
                {
                    pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
                }
                {
                    submitResponse && <Alert severity={submitResponse.severity}>{submitResponse.message}</Alert>
                }
                {/* datatable using datagrid */}
                <h3 style={{ textAlign: "center" }}>{title}</h3>
                <DataGrid
                    rows={entities?.elements}
                    columns={columns}
                    initialState={initialState}
                    pageSizeOptions={[5, 10]}
                    getRowClassName={getRowClassName}
                    paginationMode="server"
                    rowCount={rowCountState}
                    onPaginationModelChange={(params) => {
                        console.log(params)
                        setInitialState({
                            pagination: {
                                paginationModel: { page: params.page, pageSize: params.pageSize },
                            },
                        })
                        getPendingClientPayment(params.page, params.pageSize,query)
                    }}
                    
                />
                {/* Postpone dialog */}
                <Dialog open={postponeDialogOpen} onClose={handleCloseDialog}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                            '& .MuiFormControl-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        style={{ padding: "35px" }}
                    >
                        {
                            submitResponse && <Alert severity={submitResponse.severity}>{submitResponse.message}</Alert>
                        }
                        <h2 id="user-form">Change due date</h2>
                        <div>
                            <CustomDateInput formValues={postponeFormValues} handleChange={handlePostponeDateChange} label="dueDate" />
                        </div>
                        <br />
                        <div style={{ width: "100%" }}>
                            {
                                <Button onClick={handlePostpone} variant="contained" color="primary">
                                    Postpone
                                </Button>
                            }
                        </div>
                    </Box>
                </Dialog>

                {/* Process dialog */}

                <Dialog open={processDialogOpen} onClose={handleCloseDialog}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                            '& .MuiFormControl-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        style={{ padding: "35px" }}
                    >
                        {
                            submitResponse && <Alert severity={submitResponse.severity}>{submitResponse.message}</Alert>
                        }
                        <h2 id="user-form">Process the payment</h2>
                        <div>
                            <Typography variant="subtitle2">
                                . Application: {proccessPaymentPage.clientName}
                            </Typography>
                            <br />
                            <Typography variant="subtitle2">
                                . Montant: {proccessPaymentPage.amount}
                            </Typography>
                            <br />
                            <Typography variant="subtitle2">
                                . Mode de paiement: {proccessPaymentPage.paymentMethodName}
                            </Typography>
                            <br />
                            <Typography variant="subtitle2">
                                . Portefeuille: <br /> {proccessPaymentPage.walletInformation}
                            </Typography>
                        </div>
                        <br />
                        <div>
                            <CustomInput formValues={processFormValues} handleChange={handleChange} label="operatorReference" required={true} />
                        </div>
                        <br />
                        <div style={{ width: "100%" }}>
                            {
                                <Button onClick={handleProcess} variant="contained" color="primary">
                                    Process
                                </Button>
                            }
                        </div>
                    </Box>
                </Dialog>
            </div>
        </>
    )
}

export default PendingTransaction