import React, { useEffect } from 'react'
import { Grid, Typography } from '../../../node_modules/@mui/material/index'
import SelectionCard from 'components/cards/selection/SelectionCard'
import { useState } from 'react';
import { getCall } from 'utilities/api';
import PaymentMethodApi from './PaymentMethodApi';
import PaymentMethodPrefix from './PaymentMethodPrefix';
import ApiRequest from './ApiRequest';
import CompanyApiPaymentMethodInformation from './CompanyApiPaymentMethodInformation';
import { get } from 'lodash';

const Api = () => {
    const [paymentMethods, setPaymentMethods] = useState([])
    const [pageSubmitResponse, setPageSubmitResponse] = useState(false)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
    const [paymentMethodApis, setPaymentMethodApis] = useState([])
    const [paymentMethodPrefix, setPaymentMethodPrefix] = useState([])
    const [apiRequests, setApiRequests] = useState([])
    const [companyPaymentMethodInformations, setCompanyPaymentMethodInformations] = useState([])
    const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState(null)

    const formatPaymentMethods = (data) => {
        const paymentMethods = data.map((paymentMethod, index) => {
            if (index === 0) {
                setSelectedPaymentMethod(paymentMethod)
            }
            return {
                name: paymentMethod.name,
                logo: paymentMethod.image,
                alt: paymentMethod.name + " Logo",
                id: paymentMethod.id,
                selected: index === 0 ? true : false
            }
        })
        return paymentMethods
    }

    useEffect(() => {
        getCall("/payment-methods/alias", setPaymentMethods, setPageSubmitResponse, formatPaymentMethods, setFirstPaymentMethodAsSelected)
    }, [])

    const getPaymentMethodApis = (paymentMethodId) => {
        console.log("paymentMethodId", paymentMethodId)
        getCall("/payment-method-apis/payment-method/" + paymentMethodId, setPaymentMethodApis, setPageSubmitResponse)
    }

    const getPaymentMethodPrefix = (paymentMethodId) => {
        getCall("/payment-method-prefixes/payment-method/" + paymentMethodId, setPaymentMethodPrefix, setPageSubmitResponse)
    }

    const getApiRequests = (paymentMethodId) => {
        getCall("/api-requests/payment-method/" + paymentMethodId, setApiRequests, setPageSubmitResponse)
    }

    const getCompanyPaymentMethodInformations = (paymentMethodId) => {
        getCall("/company-payment-method-informations/payment-method/" + paymentMethodId, setCompanyPaymentMethodInformations, setPageSubmitResponse)
    }

    const setFirstPaymentMethodAsSelected = (paymentMethods) => {
        if (paymentMethods.length > 0) {
            getPaymentMethodApis(paymentMethods[0].id)
            getPaymentMethodPrefix(paymentMethods[0].id)
            getApiRequests(paymentMethods[0].id)
            getCompanyPaymentMethodInformations(paymentMethods[0].id)
            setCurrentPaymentMethodId(paymentMethods[0].id)
        }
    }

    const handleClick = (id) => {
        // set update the selected payment method and unselect the others
        const updatedPaymentMethods = paymentMethods.map((paymentMethod) => {
            if (paymentMethod.id === id) {
                paymentMethod.selected = true
                getPaymentMethodApis(id)
                getPaymentMethodPrefix(id)
                getApiRequests(id)
                getCompanyPaymentMethodInformations(id)
                setCurrentPaymentMethodId(id)
            } else {
                paymentMethod.selected = false
            }
            return paymentMethod
        })
        setPaymentMethods(updatedPaymentMethods)
        // update the dynamic content
    }

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.5}>
                <Grid item xs={12} sx={{ mb: -2.25 }}>
                    <Typography variant="h5">Choisir un mode paiement</Typography>
                </Grid>
                {paymentMethods.map((paymentMethod, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <SelectionCard paymentMethod={paymentMethod} title="Payment method" handleClick={handleClick} />
                    </Grid>
                ))}
            </Grid>
            <br />
            <br />
            <Grid container rowSpacing={4.5} columnSpacing={2.5}>
                <Grid item xs={12} sm={12} md={6} sx={{ mb: -2.25 }}>
                    <PaymentMethodApi entities={paymentMethodApis} getPaymentMethodApis={getPaymentMethodApis} currentPaymentMethodId={currentPaymentMethodId} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <PaymentMethodPrefix entities={paymentMethodPrefix} getPaymentMethodPrefix={getPaymentMethodPrefix} currentPaymentMethodId={currentPaymentMethodId}/>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ApiRequest entities={apiRequests} getApiRequests={getApiRequests} currentPaymentMethodId={currentPaymentMethodId} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <CompanyApiPaymentMethodInformation entities={companyPaymentMethodInformations} getCompanyPaymentMethodInformations={getCompanyPaymentMethodInformations} currentPaymentMethodId={currentPaymentMethodId} />
        </>
    )
}

export default Api