import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

// render - login
const NotFoundPage = Loadable(lazy(() => import('pages/extra-pages/NotFoundPage')));

// ==============================|| AUTH ROUTING ||============================== //

const EmptyRoutes = {
  path: '/not-found',
  element: <NotFoundPage />,
  children: [
  ]
};

export default EmptyRoutes;
