// assets
import {
  TransactionOutlined,
  MoneyCollectOutlined,
  WalletOutlined
} from '@ant-design/icons';

// icons
const icons = {
  TransactionOutlined,
  MoneyCollectOutlined,
  WalletOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'transactions',
  title: 'Transactions',
  type: 'group',
  permission: 1,
  children: [
    // {
    //   id: 'pending-transactions',
    //   title: 'Paiements en attente',
    //   type: 'item',
    //   url: '/admin/pending-transactions',
    //   icon: icons.TransactionOutlined
    // },
    // {
    //   id: 'processed-transactions',
    //   title: 'Paiements traités',
    //   type: 'item',
    //   url: '/admin/processed-transactions',
    //   icon: icons.MoneyCollectOutlined
    // },
    {
      id: 'all-transactions',
      title: 'Tous les paiements',
      type: 'item',
      url: '/admin/all-transactions',
      icon: icons.WalletOutlined
    }
  ]
};

export default utilities;
