import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Alert, Box } from '../../../node_modules/@mui/material/index';
import axios from '../../../node_modules/axios/index';
import { useEffect } from 'react';
import { BASE_URL } from 'config';
import { getCall, getHeaders, postCall, putCall } from 'utilities/api';
import { validateEmailInput, validateNotNullInput } from 'utilities/validation';
import CustomSelect from 'components/form/CustomSelect';
import CustomInput from 'components/form/CustomInput';
import CustomEmailInput from 'components/form/CustomEmailInput';
import AddDeleteButtonGroup from 'components/button/AddDeleteButtonGroup';
import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { getRowClassName } from 'utilities/utilities';
import { resetFormErrors, resetFormValues, validateFormValues, setToDisabled } from 'utilities/react_utilities';

const Admin = () => {
  // mandatory states
  const [selectedIds, setSelectedIds] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [formTitle, setFormTitle] = useState("Ajouter un administrateur")
  const [showDelete, setShowDelete] = useState(false)
  const [updateButton, setUpdateButton] = useState(true)
  const [submitResponse, setSubmitResponse] = useState(false)
  const [currentAdminId, setCurrentAdminId] = useState(null)
  const [pageSubmitResponse, setPageSubmitResponse] = useState(false)

  // user defined state
  const [users, setAdmins] = useState([])
  const [usersPageData, setAdminsPageData] = useState([])

  // mandatory form data
  const [formValues, setFormValues] = useState({
    lastname: {
      value: '',
      error: false,
      helperText: '',
      validation: (value) => {
        return validateNotNullInput(value, setFormValues, 'lastname')
      }
    },
    firstname: {
      value: '',
      error: false,
      helperText: ''
    },
    email: {
      value: '',
      error: false,
      helperText: '',
      validation: (value) => {
        return validateEmailInput(value, setFormValues, 'email')
      }
    },
    gender: {
      value: '',
      error: false,
      helperText: '',
      validation: (value) => {
        return validateNotNullInput(value, setFormValues, 'gender')
      }
    },
    city: {
      value: '',
      error: false,
      helperText: '',
      validation: (value) => {
        return validateNotNullInput(value, setFormValues, 'city')
      }
    },
    activityArea: {
      value: '',
      error: false,
      helperText: '',
      validation: (value) => {
        return validateNotNullInput(value, setFormValues, 'activityArea', 'Activity area')
      }
    },
    address: {
      value: '',
      error: false,
      helperText: '',
      validation: (value) => {
        return validateNotNullInput(value, setFormValues, 'address')
      }
    },
  })

  const getAdmins = () => {
    getCall("/utilisateurs/all-admin", setAdmins, setPageSubmitResponse, undefined)
  }

  useEffect(() => {
    getAdmins()
    getCall("/users-page-data/all", setAdminsPageData, setPageSubmitResponse, undefined)
  }, [])

  const columns = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'firstname', headerName: 'Prénom', width: 150 },
    { field: 'lastname', headerName: 'Nom', width: 150 },
    {
      field: 'genderName',
      headerName: 'Genre',
      width: 150,
    },
    {
      field: 'permissionName',
      headerName: 'Permission',
      width: 150,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, true) }}>Modifier</Button>
      ),
    },
    // see more link with params.id
    {
      field: 'more',
      headerName: 'Plus',
      width: 150,
      renderCell: (params) => (
        <Button variant="outlined" color="primary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, false) }}>Détail</Button>
      ),
    }
  ];

  const buildEntity = (formValues) => {
    return {
      firstname: formValues.firstname.value,
      lastname: formValues.lastname.value,
      email: formValues.email.value,
      address: formValues.address.value,
      city: {
        id: formValues.city.value
      },
      activityArea: {
        id: formValues.activityArea.value
      },
      gender: {
        id: formValues.gender.value
      },
      permission: {
        id: 2
      }
    }
  }


  const handleAddAdmin = (e) => {
    // execute validation in formValues
    const success = validateFormValues(formValues)
    if (success) {
      const user = buildEntity(formValues)
      user.password = "iboniaDefault1234!"
      postCall("/utilisateurs", user, resetFormValues, formValues, setFormValues, setSubmitResponse, handleCloseAddDialog, getAdmins)
    }
  }

  const handleUpdateAdmin = (e) => {
    const success = validateFormValues(formValues)
    if (success) {
      let currentAdmin = buildEntity(formValues)
      currentAdmin.id = currentAdminId
      putCall("/utilisateurs", currentAdmin, setSubmitResponse, getAdmins)
    }
  }

  const handleUpdateClick = (params, update) => {
    resetFormErrors(formValues)
    setSubmitResponse(false)
    // mock a fake user
    axios.get(`${BASE_URL}/utilisateurs/${params.id}`, getHeaders()).then((response) => {
      const user = response.data.data
      setCurrentAdminId(user.id)
      if (!update) {
        setUpdateButton(false)
        // set formValues default value
        setToDisabled(formValues, setFormValues, user, true)
      } else {
        setUpdateButton(true)
        // set formValues default value
        setToDisabled(formValues, setFormValues, user, false)
      }
      setFormTitle(update ? "Modifier an admin" : "Info of an admin")
      setAddDialogOpen(true);
    }
    ).catch((error) => {
      setSubmitResponse({ severity: "error", message: error.response.data.message })
    }
    )
  };

  const handleConfirmDelete = () => {
    // Perform delete logic
    axios.post(`${BASE_URL}/utilisateurs/multiple-delete`, selectedIds, getHeaders()).then((response) => {
      setPageSubmitResponse({ severity: "success", message: "Admins deleted successfully" })
      getAdmins()
    }
    ).catch((error) => {
      setPageSubmitResponse({ severity: "error", message: error.response.data.message })
    }
    ).finally(() => {
      setDialogOpen(false);
    }).then(() => {
      setTimeout(() => {
        setPageSubmitResponse(false)
      }, 3000)
    })
    // Close the dialog
  };

  // not to be touched anymore

  // done
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
      },
    }));
  }

  // done
  const handleSelectionChange = (selectionModel) => {
    // replace existing selectedIds with selectionModel
    selectionModel.length !== 0 ? setShowDelete(true) : setShowDelete(false)
    setSelectedIds(selectionModel);
  };

  // done
  const handleDeleteClick = () => {
    setDialogOpen(true); // Open the dialog
  };

  // done
  const handleCloseDialog = () => {
    // Close the dialog without performing delete
    setDialogOpen(false);
  };

  // done
  const handleAddClick = () => {
    setSubmitResponse(false)
    setUpdateButton(true)
    setFormTitle("Ajouter un administrateur")
    // set formValues error to false
    resetFormErrors(formValues)
    resetFormValues(formValues, setFormValues)
    setAddDialogOpen(true);
  };

  // done
  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  return (
    <div style={{ height: '70vh', width: '100%', backgroundColor: 'white' }}>
      {/* add and delete button group */}
      <AddDeleteButtonGroup handleAddClick={handleAddClick} handleDeleteClick={handleDeleteClick} showDelete={showDelete} />
      {
        pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
      }
      {/* datatable using datagrid */}
      <h3 style={{ textAlign: "center" }}>Administrateurs</h3>
        <DataGrid
          rows={users}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          getRowClassName={getRowClassName}
          onRowSelectionModelChange={(item) => handleSelectionChange(item)}
          checkboxSelection
        />
      {/* dialog for delete items */}
      <ConfirmDialog dialogOpen={dialogOpen} handleCloseDialog={handleCloseDialog} message={`Are you sure you want to delete those ${selectedIds.length} item(s)?`} handleConfirm={handleConfirmDelete} />
      {/* Ajouter dialog */}
      <Dialog open={addDialogOpen} onClose={handleCloseAddDialog}>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            '& .MuiFormControl-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
          style={{ padding: "35px" }}
        >
          {
            submitResponse && <Alert severity={submitResponse.severity}>{submitResponse.message}</Alert>
          }
          <h2 id="user-form">{formTitle}</h2>
          <div>
            <CustomInput formValues={formValues} handleChange={handleChange} title={"Firstname"} label={"Firstname"} required={false} />
            <CustomInput formValues={formValues} handleChange={handleChange} title={"Lastname"} label={"Lastname"} required={true} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CustomEmailInput formValues={formValues} handleChange={handleChange} title={"Email"} label={"Email"} required={true} />
            <CustomSelect formValues={formValues} handleChange={handleChange} items={usersPageData.genders} title={"Gender"} label={"Gender"} />
          </div>
          <div>
            <CustomSelect formValues={formValues} handleChange={handleChange} items={usersPageData.cities} title={"City"} label={"City"} />
            <CustomSelect formValues={formValues} handleChange={handleChange} items={usersPageData.activityAreas} title={"Activity area"} label={"activityArea"} />
          </div>
          <div>
            <CustomInput formValues={formValues} handleChange={handleChange} title={"Adress"} label={"Address"} required={true} />
          </div>
          <br />
          <div style={{ width: "100%" }}>
            {
              updateButton && <Button onClick={formTitle === "Ajouter un administrateur" ? handleAddAdmin : handleUpdateAdmin} variant="contained" color="primary">
                {formTitle === "Ajouter un administrateur" ? "Ajouter" : "Modifier"}
              </Button>
            }
          </div>
        </Box>
      </Dialog>
    </div>
  )
}

export default Admin