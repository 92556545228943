import React from 'react'
import { TextField } from '../../../node_modules/@mui/material/index'
import { lowerCaseFirstLetter } from 'utilities/utilities'

const CustomEmailInput = ({ formValues, handleChange, label, required , title}) => {
    return (
        <TextField
            disabled={formValues[lowerCaseFirstLetter(label)].disabled}
            id={lowerCaseFirstLetter(label)}
            name={lowerCaseFirstLetter(label)}
            label={title!==undefined ? title:label}
            type="email"
            defaultValue={formValues[lowerCaseFirstLetter(label)].value}
            required={required}
            onChange={handleChange}
            error={formValues[lowerCaseFirstLetter(label)].error}
            helperText={formValues[lowerCaseFirstLetter(label)].error && formValues[lowerCaseFirstLetter(label)].helperText}
        />
    )
}

export default CustomEmailInput