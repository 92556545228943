import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from 'config';
import { getHeaders } from 'utilities/api';
import { Alert } from '../../../node_modules/@mui/material/index';

const Parameter = () => {
    // Create a state variable for formValues
    const [formValues, setFormValues] = useState("");
    const [pageSubmitResponse, setPageSubmitResponse] = useState(false)

    useEffect(() => {
        axios.get(`${BASE_URL}/parameters/1`, getHeaders()).then((res) => {
            // console.log(res.data.data);
            // Modifier the state variable
            setFormValues(res.data.data);
        });
    }, []);

    const handleUpdate = () => {
        if(formValues.valeur < 0 || formValues.valeur > 100){
            setPageSubmitResponse({ severity: "error", message: "Commission value must be between 0 and 100" })
            return;
        }

        const newParameter = {
            valeur: formValues.valeur
        }
        console.log(formValues, newParameter, formValues.id);
        const id = formValues.id;
        axios.put(`${BASE_URL}/parameters/${id}`, newParameter, getHeaders()).then((res) => {
            // console.log(res.data.data);
            // Modifier the state variable
            setFormValues(res.data.data);
            setPageSubmitResponse({ severity: "success", message: "Commission value updated successfully" })
        }
        ).catch((error) => {
            console.log(error);
            setPageSubmitResponse({ severity: "error", message: "Error updating commission value" })
        }
        );
    };

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    '& .MuiFormControl-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                style={{ padding: '35px', backgroundColor: 'white' }}
            >
                {
                    pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
                }
                <h3>Mettre à jour la commission</h3>
                <TextField
                    value={formValues.valeur}
                    onChange={(e) => {
                        setFormValues({ ...formValues, valeur: e.target.value })
                    }
                    }
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                />
                <br />
                <br />
                <Button onClick={handleUpdate} variant="contained" color="primary">
                    Modifier
                </Button>
            </Box>
        </>
    );
};

export default Parameter;
