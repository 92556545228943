import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Alert, Box } from '../../../node_modules/@mui/material/index';
import axios from '../../../node_modules/axios/index';
import { useEffect } from 'react';
import { BASE_URL } from 'config';
import { getCall, getHeaders, postCall, putCall } from 'utilities/api';
import { validateNotNullInput } from 'utilities/validation';
import CustomSelect from 'components/form/CustomSelect';
import CustomInput from 'components/form/CustomInput';
import AddDeleteButtonGroup from 'components/button/AddDeleteButtonGroup';
import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { getRowClassName } from 'utilities/utilities';
import { resetFormErrors, resetFormValues, validateFormValues, setToDisabled } from 'utilities/react_utilities';

const PaymentMethodPrefix = ({entities, getPaymentMethodPrefix, currentPaymentMethodId}) => {
    // mandatory states
    const [selectedIds, setSelectedIds] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const labelInfo = "Préfixes"
    const title = "Préfixes"
    const [formTitle, setFormTitle] = useState("Ajouter a "+labelInfo)
    const [showDelete, setShowDelete] = useState(false)
    const [updateButton, setUpdateButton] = useState(true)
    const [submitResponse, setSubmitResponse] = useState(false)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [pageSubmitResponse, setPageSubmitResponse] = useState(false)

    // user defined state
    const [pageData, setPageData] = useState([])

    // mandatory form data
    const [formValues, setFormValues] = useState({
        prefix: {
            value: "",
            error: false,
            helperText: "",
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'prefix')
            }
        }
    })

    const baseEndpoint = "/payment-method-prefixes"

    useEffect(() => {
        getCall("/apis-page-data/all", setPageData, setPageSubmitResponse, undefined)
    }, [])

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'prefix', headerName: 'Préfixe', width: 130 },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, true) }}>Modifier</Button>
            ),
        },
        // see more link with params.id
        {
            field: 'more',
            headerName: 'Plus',
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" color="primary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, false) }}>Détails</Button>
            ),
        }
    ];

    const buildEntity = (formValues) => {
        return {
            prefix: formValues.prefix.value,
            paymentMethod: {
                id: currentPaymentMethodId
            }
        }
    }


    const handleAddEntity = (e) => {
        // execute validation in formValues
        const success = validateFormValues(formValues)
        if (success) {
            const entity = buildEntity(formValues)
            postCall(`${baseEndpoint}`, entity, resetFormValues, formValues, setFormValues, setSubmitResponse, handleCloseAddDialog,getPaymentMethodPrefix(currentPaymentMethodId))
        }
    }

    const handleUpdateEntity = (e) => {
        console.log("Modifier is called")
        const success = validateFormValues(formValues)
        if (success) {
            let currentEntity = buildEntity(formValues)
            currentEntity.id = currentUserId
            putCall(`${baseEndpoint}`, currentEntity, setSubmitResponse)
            setTimeout(() => {
                getPaymentMethodPrefix(currentPaymentMethodId)
            }, 500);
        }
    }

    const handleUpdateClick = (params, update) => {
        resetFormErrors(formValues)
        setSubmitResponse(false)
        // mock a fake user
        axios.get(`${BASE_URL + baseEndpoint}/${params.id}`, getHeaders()).then((response) => {
            const entity = response.data.data
            setCurrentUserId(entity.id)
            if (!update) {
                setUpdateButton(false)
                // set formValues default value
                setToDisabled(formValues, setFormValues, entity, true)
            } else {
                setUpdateButton(true)
                // set formValues default value
                setToDisabled(formValues, setFormValues, entity, false)
            }
            setFormTitle(update ? "Modifier a "+labelInfo : "Info of a "+labelInfo)
            setAddDialogOpen(true);
        }
        ).catch((error) => {
            setSubmitResponse({ severity: "error", message: error.response.data.message })
        }
        )
    };

    const handleConfirmDelete = () => {
        // Perform delete logic
        axios.post(`${BASE_URL + baseEndpoint}/multiple-delete`, selectedIds, getHeaders()).then((response) => {
            setPageSubmitResponse({ severity: "success", message: `${labelInfo}s deleted successfully` })
            getPaymentMethodPrefix(currentPaymentMethodId)
        }
        ).catch((error) => {
            setPageSubmitResponse({ severity: "error", message: error.response.data.message })
        }
        ).finally(() => {
            setDialogOpen(false);
        }).then(() => {
            setTimeout(() => {
                setPageSubmitResponse(false)
            }, 3000)
        })
        // Close the dialog
    };

    // not to be touched anymore

    // done
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
            },
        }));
    }

    // done
    const handleSelectionChange = (selectionModel) => {
        // replace existing selectedIds with selectionModel
        selectionModel.length !== 0 ? setShowDelete(true) : setShowDelete(false)
        setSelectedIds(selectionModel);
    };

    // done
    const handleDeleteClick = () => {
        setDialogOpen(true); // Open the dialog
    };

    // done
    const handleCloseDialog = () => {
        // Close the dialog without performing delete
        setDialogOpen(false);
    };

    // done
    const handleAddClick = () => {
        setSubmitResponse(false)
        setUpdateButton(true)
        setFormTitle("Ajouter a "+labelInfo)
        // set formValues error to false
        resetFormErrors(formValues)
        resetFormValues(formValues, setFormValues)
        setAddDialogOpen(true);
    };

    // done
    const handleCloseAddDialog = () => {
        setAddDialogOpen(false);
    };

    return (
        <div style={{ height: '70vh', width: '100%', backgroundColor: 'white' }}>
            {/* add and delete button group */}
            <AddDeleteButtonGroup handleAddClick={handleAddClick} handleDeleteClick={handleDeleteClick} showDelete={showDelete} />
            {
                pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
            }
            {/* datatable using datagrid */}
            <h3 style={{textAlign:"center"}}>{title}</h3>
            <DataGrid
                rows={entities}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={getRowClassName}
                onRowSelectionModelChange={(item) => handleSelectionChange(item)}
                checkboxSelection
            />
            {/* dialog for delete items */}
            <ConfirmDialog dialogOpen={dialogOpen} handleCloseDialog={handleCloseDialog} message={`Are you sure you want to delete those ${selectedIds.length} item(s)?`} handleConfirm={handleConfirmDelete} />
            {/* Ajouter dialog */}
            <Dialog open={addDialogOpen} onClose={handleCloseAddDialog}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        '& .MuiFormControl-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    style={{ padding: "35px" }}
                >
                    {
                        submitResponse && <Alert severity={submitResponse.severity}>{submitResponse.message}</Alert>
                    }
                    <h2 id="user-form">{formTitle}</h2>
                    <div>
                        <CustomInput formValues={formValues} handleChange={handleChange} label={"prefix"} required={true} />
                    </div>
                    <br />
                    <div style={{ width: "100%" }}>
                        {
                            updateButton && <Button onClick={formTitle === `Ajouter a ${labelInfo}` ? handleAddEntity : handleUpdateEntity} variant="contained" color="primary">
                                {formTitle === `Ajouter a ${labelInfo}` ? "Ajouter" : "Modifier"}
                            </Button>
                        }
                    </div>
                </Box>
            </Dialog>
        </div>
    )
}

export default PaymentMethodPrefix