import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Alert, Button } from '../../../node_modules/@mui/material/index';
import { useEffect } from 'react';
import { getCall } from 'utilities/api';
import { getRowClassName, updatePageTitles } from 'utilities/utilities';
import { useNavigate, useParams } from '../../../node_modules/react-router-dom/dist/index';
import { set } from 'lodash';
import { f } from '../../../node_modules/x-data-grid/lib/xDataGrid.umd';

const PaymentDetails = () => {
  // mandatory states
  const [selectedIds, setSelectedIds] = useState([]);
  const labelInfo = "Détails du paiement"
  const title = "Détails du paiement"
  const [showDelete, setShowDelete] = useState(false)
  const [pageSubmitResponse, setPageSubmitResponse] = useState(false)
  const [entities, setEntities] = useState([])

  const { paymentId } = useParams()

  const [columns, setColumns] = useState([]);

  const updateColumns = (data) => {
    console.log(data)
    data[0].senderNumber !== null ?
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        {field: 'reference', headerName: 'Référence', width: 300},
        { field: 'description', headerName: 'Description', width: 150},
        { field: 'senderNumber', headerName: 'Numéro du créditeur', width: 150 },
        { field: 'amount', headerName: 'Montant', width: 150 },
        { field: 'paymentDate', headerName: 'Date et heure de la transaction', width: 200}
      ]) : setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        {field: 'reference', headerName: 'Référence', width: 300},
        { field: 'description', headerName: 'Description', width: 150},
        { field: 'amount', headerName: 'Montant', width: 150 },
        // { field: 'pan', headerName: 'Numéro de la carte', width: 150 },
        // { field: 'expiryMonth', headerName: 'Mois d\'expiration', width: 150 },
        // { field: 'expiryYear', headerName: 'Année d\'expiration', width: 150 },
        // { field: 'securityCode', headerName: 'Code de sécurité', width: 150 },
        { field: 'paymentDate', headerName: 'Date et heure de la transaction', width: 200}
      ])
  }

  useEffect(() => {
    updatePageTitles(title)
    getCall(`/clients/payment-details/${paymentId}`, setEntities, setPageSubmitResponse, undefined, updateColumns)
  }, [])

  // not to be touched anymore


  return (
    <div style={{ height: '70vh', width: '100%', backgroundColor: 'white', paddingTop: "10px" }}>
      {/* add and delete button group */}
      {
        pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
      }
      {/* datatable using datagrid */}
      <h3 style={{ textAlign: "center" }}>{title}</h3>
      <DataGrid
        rows={entities}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        getRowClassName={getRowClassName}
      />
    </div>
  )
}

export default PaymentDetails