import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Authorization from 'authorization/Authorization';
import { Navigate } from 'react-router-dom';
import Admin from 'pages/user/Admin';
import Api from 'pages/apis/Api';
import Headers from 'pages/apis/Headers';
import User from 'pages/user/User';
import Applications from 'pages/user/Applications';
import PendingTransaction from 'pages/payment/PendingTransaction';
import ProcessedTransaction from 'pages/payment/ProcessedTransaction';
import PaymentDetails from 'pages/payment/PaymentDetails';
import Bodies from 'pages/apis/Bodies';
import ClientApiPaymentMethodInformation from 'pages/apis/ClientApiPaymentMethodInformation';
import ClientAppInformations from 'pages/apis/ClientAppInformations';
import Parameter from 'pages/parameter/Parameter';
import AllTransactions from 'pages/payment/AllTransactions';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

let user = null;

try {
  user = atob(localStorage.getItem("user"))
  user = JSON.parse(user)
} catch (error) {
  user = {
    permissionId: 0
  }
}

const MainRoutes = {
  path: '/admin',
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <Navigate to="/not-found" replace />
    },
    {
      path: 'dashboard',
      element: <Authorization condition={user.permissionId != 0} redirectPath={"/auth/login"} component={DashboardDefault} />
    },
    {
      path: 'administrators',
      element: <Authorization condition={user.permissionId >= 2} redirectPath={"/auth/login"} component={Admin} />
    }, {
      path: 'merchants',
      element: <Authorization condition={user.permissionId >= 2} redirectPath={"/auth/login"} component={User} />
    },
    {
      path: 'apis',
      element: <Authorization condition={user.permissionId >= 2} redirectPath={"/auth/login"} component={Api} />
    },
    {
      path: 'apis/:apiRequestId/headers',
      element: <Authorization condition={user.permissionId >= 2} redirectPath={"/auth/login"} component={Headers} />
    }, {
      path: "apis/:apiRequestId/bodies",
      element: <Authorization condition={user.permissionId >= 2} redirectPath={"/auth/login"} component={Bodies} />
    }, {
      path: "user/:userId/applications",
      element: <Authorization condition={user.permissionId >= 2} redirectPath={"/auth/login"} component={Applications} />
    },
    , {
      path: "pending-transactions",
      element: <Authorization condition={user.permissionId != 0} redirectPath={"/auth/login"} component={PendingTransaction} />
    }, {
      path: "processed-transactions",
      element: <Authorization condition={user.permissionId != 0} redirectPath={"/auth/login"} component={ProcessedTransaction} />
    }, {
      path: "all-transactions",
      element: <Authorization condition={user.permissionId != 0} redirectPath={"/auth/login"} component={AllTransactions} />
    }, {
      path: "payment-details/:paymentId",
      element: <Authorization condition={user.permissionId >= 0} redirectPath={"/auth/login"} component={PaymentDetails} />
    }, {
      path: "client/:clientId/payment-method-informations",
      element: <Authorization condition={user.permissionId != 0} redirectPath={"/auth/login"} component={ClientAppInformations} />
    }, {
      path: "commission",
      element: <Authorization condition={user.permissionId >= 2} redirectPath={"/auth/login"} component={Parameter} />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    },
    {
      path: "*",
      element: <Navigate to="/not-found" replace />
    }
  ]
};

export default MainRoutes;
