import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Alert, Box, Button, Grid } from '../../../node_modules/@mui/material/index';
import { useEffect } from 'react';
import { getCall } from 'utilities/api';
import { formatMoney, getRowClassName, jsDateToString } from 'utilities/utilities';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import CustomInput from 'components/form/CustomInput';
import CustomDateInput from 'components/form/CustomDateInput';
import CustomSelect from 'components/form/CustomSelect';
import { get, set } from 'lodash';
import PaymentPopIn from './PaymentPopIn';

const AllTransactions = () => {
    // mandatory states
    const [selectedIds, setSelectedIds] = useState([]);
    const labelInfo = "Tous les paiements"
    const title = "Tous les paiements"
    const [showDelete, setShowDelete] = useState(false)
    const [pageSubmitResponse, setPageSubmitResponse] = useState(false)
    const [query, setQuery] = useState("")
    const [entities, setEntities] = useState({
        listResponse: {
            elements: [],
            count: 0,
            page: 0,
            pageSize: 0,
        }
    })
    const [clients, setClients] = useState([])
    const [etats, setEtats] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [rowSelected, setRowSelected] = useState("0 Ar")
    const [paymentPopIn, setPaymentPopIn] = useState(false)
    const [userLoggedIn, setUserLoggedIn] = useState({})
    const [userClients, setUserClients] = useState([])

    let rowCount = 10

    const [rowCountState, setRowCountState] = useState(rowCount);

    const [initialState, setInitialState] = useState({
        pagination: {
            paginationModel: { page: 0, pageSize: 10 },
        },
    });
    const formatEntities = (data) => {
        // console.log("row: ", rowSelected)
        setTotalAmount(data.totalAmount)
        data = data.listResponse
        setInitialState(
            {
                pagination: {
                    paginationModel: { page: data.page, pageSize: data.pageSize },
                },
            }
        )
        // console.log("Data count : ", data)
        setRowCountState(data.count)
    }

    const getProcessedClientPayment = (page, pageSize, localQuery) => {
        getCall(`/all-client-payment-detail?page=${page}&size=${pageSize}&${localQuery}`, setEntities, setPageSubmitResponse, undefined, formatEntities)
        setEtats([
            { id: 0, name: "Non reglé" },
            { id: 1, name: "Reglé" },
        ])
        setSelectedIds(getSelectedIds())
    }

    const manageClients = (data) => {
        setUserClients(data)
        let lcq = ""
        let user = JSON.parse(atob(localStorage.getItem("user")))
        if (data.length > 0) {
            lcq = user.permissionId<2 ? "clientId=" + data[0].id : ""
            setQuery(lcq)
            setSearchFormValues(
                (prevState) =>{
                    return {
                        ...prevState,
                        clientId: {
                            ...prevState.clientId,
                            value: data[0].id,
                        },
                    };
                }
            )
        }
        getProcessedClientPayment(initialState.pagination.paginationModel.page, initialState.pagination.paginationModel.pageSize, lcq)
    }

    useEffect(() => {
        let user = JSON.parse(atob(localStorage.getItem("user")))
        getCall(`/clients/user/${user.id}`, setUserClients, setPageSubmitResponse, undefined, manageClients)
        console.log("User: ", user)
        setUserLoggedIn(user)
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
        getCall(`/clients/alias`, setClients, setPageSubmitResponse)
        localStorage.setItem("selectedIds", JSON.stringify([]))
        localStorage.setItem("uniqueIdsAndAmount", JSON.stringify([]))
    }, [])

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'clientName', headerName: 'Application', width: 130 },
        { field: 'paymentMethodName', headerName: 'Mode de paiement', width: 130 },
        { field: 'senderNumber' , headerName: 'Numéro de téléphone', width: 130},
        { field: 'clientEmail', headerName: 'Email', width: 130},
        { field: 'reference', headerName: 'Référence', width: 130 },
        { field: 'description', headerName: 'Description', width: 130 },
        { field: 'designation', headerName: 'Désignation', width: 130},
        { field: 'amount', headerName: 'Volume', width: 130 },
        { field: 'fee', headerName: 'Frais', width: 130 },
        { field: 'isPaid', headerName: "Etat", width: 130 },
        { field: 'paymentDate', headerName: 'Date de la transaction', width: 130 }
    ];

    const navigate = useNavigate()

    // not to be touched anymore
    const [searchFormValues, setSearchFormValues] = useState({
        clientId: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'clientId')
            }
        },
        paymentMethodId: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'paymentMethodId')
            }
        },
        reference: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'reference')
            }
        },
        minAmount: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'minAmount')
            }
        },
        maxAmount: {
            value: '',
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'maxAmount')
            }
        },
        minPaymentDate: {
            value: "",
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'minPaymentDate')
            }
        },
        maxPaymentDate: {
            value: "",
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'maxPaymentDate')
            }
        },
        isPaid: {
            value: "",
            error: false,
            helperText: '',
            validation: (value) => {
                return validateNotNullInput(value, setSearchFormValues, 'isPaid')
            }
        },
    })

    const handleSearchFormChange = (e, label) => {
        // console.log(label)
        const target = e.target
        if (target !== undefined) {
            const { name, value } = e.target;
            setSearchFormValues((prevState) => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value,
                },
            }));
        } else {
            let newDate = e;
            const date = new Date(newDate.$d)
            const value = jsDateToString(date);
            setSearchFormValues((prevState) => ({
                ...prevState,
                [label]: {
                    ...prevState[label],
                    value,
                },
            }));
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchFormValues((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
            },
        }));
    }

    const handleSearch = () => {
        let localQuery = buildSearchQuery(searchFormValues)
        // console.log(localQuery)
        getProcessedClientPayment(initialState.pagination.paginationModel.page, initialState.pagination.paginationModel.pageSize, localQuery)
        // setRowSelected("0 Ar")
        setQuery(localQuery)
    }

    const buildSearchQuery = (searchFormValues) => {
        let localQuery = "";
        if (searchFormValues.clientId.value !== "") {
            localQuery += "clientId=" + searchFormValues.clientId.value + "&"
        }
        if (searchFormValues.minAmount.value !== "") {
            localQuery += "minAmount=" + searchFormValues.minAmount.value + "&"
        }
        if (searchFormValues.maxAmount.value !== "") {
            localQuery += "maxAmount=" + searchFormValues.maxAmount.value + "&"
        }
        if (searchFormValues.minPaymentDate.value !== "") {
            localQuery += "minPaymentDate=" + searchFormValues.minPaymentDate.value + "&"
        }
        if (searchFormValues.maxPaymentDate.value !== "") {
            localQuery += "maxPaymentDate=" + searchFormValues.maxPaymentDate.value + "&"
        }
        if (searchFormValues.reference.value !== "") {
            localQuery += "reference=" + searchFormValues.reference.value + "&"
        }
        if (searchFormValues.isPaid.value !== "") {
            localQuery += "isPaid=" + searchFormValues.isPaid.value + "&"
        }
        if (searchFormValues.paymentMethodId.value !== "") {
            localQuery += "paymentMethodId=" + searchFormValues.paymentMethodId.value + "&"
        }


        return localQuery;
    }

    const removeNotInCurrentPage = (uniqueIds, selectionModel) => {
        let elements = entities.listResponse.elements
        console.log("Elements: ", elements)
        console.log("Selection model: ", selectionModel)
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (!selectionModel.includes(element.id)) {
                uniqueIds = uniqueIds.filter(e => e !== element.id)
            }
        }
        return uniqueIds
    }

    const handleSelectionChange = (selectionModel) => {
        console.log("Selection model : ", selectionModel)
        // save selection model to local storage
        // setSelectedIds(selectionModel)
        // if(selectionModel.length > 0){
        const selectedIdsStorage = JSON.parse(localStorage.getItem("selectedIds"))
        const updatedIds = selectedIdsStorage.concat(selectionModel)
        let uniqueIds = [...new Set(updatedIds)]
        uniqueIds = removeNotInCurrentPage(uniqueIds, selectionModel)

        let selectionModelIdsAndAmount = getIdsAndSum(selectionModel);
        console.log("Selection model ids and amount: ", selectionModelIdsAndAmount)
        let uniqueIdsAndAmountArray = getUniqueIdsAndSum(selectionModelIdsAndAmount, uniqueIds);
        let sumArray = sumAmount(uniqueIdsAndAmountArray)
        setRowSelected(formatMoney(sumArray))
        setSelectedIds(uniqueIds)
        // setRowSelected(getAmountSum(uniqueIds))
        console.log("Unique ids: ", uniqueIds)
        localStorage.setItem("selectedIds", JSON.stringify(uniqueIds))
        // }
    };

    const sumAmount = (uniqueIdsAndAmountArray) => {
        let sum = 0
        for (let i = 0; i < uniqueIdsAndAmountArray.length; i++) {
            const element = uniqueIdsAndAmountArray[i];
            sum += element.amount
        }
        return sum
    }

    const getUniqueIdsAndSum = (selectionModelIdsAndAmount, uniqueIds) => {
        console.log("Selection model ids and amount: ", localStorage.getItem("uniqueIdsAndAmount"))
        const currentIdsAndAmount = localStorage.getItem("uniqueIdsAndAmount") !== undefined && localStorage.getItem("uniqueIdsAndAmount") !== null ? JSON.parse(localStorage.getItem("uniqueIdsAndAmount")) : []
        // let if selectionModel.id not in currentIdsAndAmount.id then add it
        let uniqueIdsAndAmount = currentIdsAndAmount?.concat(selectionModelIdsAndAmount)
        uniqueIdsAndAmount = uniqueIdsAndAmount?.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
        // remove not in uniqueIds
        uniqueIdsAndAmount = uniqueIdsAndAmount.filter((v) => uniqueIds.includes(v.id))
        console.log("Unique ids and amount: ", uniqueIdsAndAmount)
        localStorage.setItem("uniqueIdsAndAmount", JSON.stringify(uniqueIdsAndAmount))
        return uniqueIdsAndAmount
    }

    const getIdsAndSum = (selectionModel) => {
        let arrayOfIdAndAmount = []
        for (let i = 0; i < entities.listResponse.elements.length; i++) {
            const element = entities.listResponse.elements[i];
            if (selectionModel.includes(element.id)) {
                let amount = element.amount.slice(0, -3)
                amount = amount.replace(",", "")
                amount = parseFloat(amount)
                arrayOfIdAndAmount.push({ id: element.id, amount: amount })
            }
        }
        return arrayOfIdAndAmount
    }

    const getAmountSum = (uniqueIds) => {
        let sum = 0
        console.log("Unique ids: ", entities)
        for (let i = 0; i < entities.listResponse.elements.length; i++) {
            const element = entities.listResponse.elements[i];
            if (uniqueIds.includes(element.id)) {
                // declare an amount in double where last 3 chars are remove before parsing
                let amount = element.amount.slice(0, -3)
                amount = amount.replace(",", "")
                amount = parseFloat(amount)
                sum += amount
            }
        }
        return sum
    }

    const getSelectedIds = () => {
        const selectedIds = JSON.parse(localStorage.getItem("selectedIds"))
        // console.log("selectedIds: ",selectedIds)
        return selectedIds
    }
    return (
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    '& .MuiFormControl-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                style={{ padding: "35px", backgroundColor: "white" }}
            >
                <h3></h3>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomSelect
                            formValues={searchFormValues}
                            handleChange={handleChange}
                            items={userLoggedIn.permissionId>=2 ? clients : userClients}
                            label={"clientId"}
                            title={"Client"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CustomSelect
                                formValues={searchFormValues}
                                handleChange={handleChange}
                                items={[
                                    { id: 2, name: "MVola" },
                                    { id: 3, name: "Airtel Money" },
                                    { id: 4, name: "SOGECOMMERCE" },
                                    { id: 5, name: "Orange Money" }
                                ]}
                                label={"paymentMethodId"}
                                title={"Mode de paiement"}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomInput
                            formValues={searchFormValues}
                            handleChange={handleSearchFormChange}
                            title={"Référence"}
                            label="reference"
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomInput
                            formValues={searchFormValues}
                            handleChange={handleSearchFormChange}
                            title={"Montant minimum"}
                            label="minAmount"
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomInput
                            formValues={searchFormValues}
                            handleChange={handleSearchFormChange}
                            title={"Montant maximum"}
                            label="maxAmount"
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomDateInput
                            formValues={searchFormValues}
                            handleChange={handleSearchFormChange}
                            title={"Échéance minimum"}
                            label="minPaymentDate"
                            required={false}
                            noDefault={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomDateInput
                            formValues={searchFormValues}
                            handleChange={handleSearchFormChange}
                            title={"Échéance maximum"}
                            label="maxPaymentDate"
                            required={false}
                            noDefault={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomSelect
                            formValues={searchFormValues}
                            handleChange={handleChange}
                            items={etats}
                            label={"isPaid"}
                            title={"Etat"}
                        />
                    </Grid>
                </Grid>
                <br />
                <Button onClick={handleSearch} variant="contained" color="primary">
                    Valider
                </Button>
            </Box>
            <br />
            <div style={{ height: '70vh', width: '100%', backgroundColor: 'white', paddingTop: "10px" }}>
                {
                    ((rowSelected) !== "0 Ar" && userLoggedIn.permissionId >= 2) && <div style={{ textAlign: "right", marginRight: "20px" }}>
                        <Button variant="contained" color="primary" className="crud-button" onClick={() => setPaymentPopIn(true)}>
                            Régler
                        </Button>
                    </div>
                }
                {/* add and delete button group */}
                <h3 style={{ textAlign: "right", marginRight: "20px" }}><b>Montant total: {totalAmount}</b></h3>
                {
                    userLoggedIn.permissionId >= 2 && 
                        <h3 style={{ textAlign: "right", marginRight: "20px" }}><b>Total de la sélection: {rowSelected}</b></h3>
                }

                {
                    pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
                }
                {/* datatable using datagrid */}
                <h3 style={{ textAlign: "center" }}>{title}</h3>
                <DataGrid
                    rows={entities?.listResponse?.elements}
                    columns={columns}
                    initialState={initialState}
                    pageSizeOptions={[10]}
                    getRowClassName={getRowClassName}
                    paginationMode="server"
                    rowCount={rowCountState}
                    onPaginationModelChange={(params) => {
                        console.log("Params: ", params)
                        setInitialState({
                            pagination: {
                                paginationModel: { page: params.page, pageSize: params.pageSize },
                            },
                        })
                        getProcessedClientPayment(params.page, params.pageSize, query)
                        console.log("selectedIds: ", selectedIds)
                    }}
                    onRowSelectionModelChange={(item) => handleSelectionChange(item)}
                    rowSelectionModel={selectedIds}
                    checkboxSelection={userLoggedIn.permissionId >= 2 ? true : false}
                />
            </div>
            <PaymentPopIn show={paymentPopIn} setShow={setPaymentPopIn} elementsId={selectedIds} totalAmount={rowSelected} />
        </>
    )
}

export default AllTransactions