import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Alert, Box } from '../../../node_modules/@mui/material/index';
import axios from '../../../node_modules/axios/index';
import { useEffect } from 'react';
import { BASE_URL } from 'config';
import { getCall, getHeaders, postCall, putCall } from 'utilities/api';
import { validateNotNullInput } from 'utilities/validation';
import CustomSelect from 'components/form/CustomSelect';
import CustomInput from 'components/form/CustomInput';
import AddDeleteButtonGroup from 'components/button/AddDeleteButtonGroup';
import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { getRowClassName } from 'utilities/utilities';
import { resetFormErrors, resetFormValues, validateFormValues, setToDisabled } from 'utilities/react_utilities';

const CompanyApiPaymentMethodInformation = ({ entities, getCompanyPaymentMethodInformations, currentPaymentMethodId }) => {
    // mandatory states
    const [selectedIds, setSelectedIds] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const labelInfo = "Portefeuille de réception"
    const title = "Portefeuille de réception"
    const [formTitle, setFormTitle] = useState("Ajouter a " + labelInfo)
    const [showDelete, setShowDelete] = useState(false)
    const [updateButton, setUpdateButton] = useState(true)
    const [submitResponse, setSubmitResponse] = useState(false)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [pageSubmitResponse, setPageSubmitResponse] = useState(false)

    // user defined state
    const [pageData, setPageData] = useState([])

    // mandatory form data
    const [formValues, setFormValues] = useState({
        phonenumber: {
            value: "",
            error: false,
            helperText: ""
        },
        pan: {
            value: "",
            error: false,
            helperText: ""
        },
        expiryMonth: {
            value: "",
            error: false,
            helperText: ""
        },
        expiryYear: {
            value: "",
            error: false,
            helperText: ""
        },
        securityCode: {
            value: "",
            error: false,
            helperText: ""
        },
        company: {
            value: "",
            error: false,
            helperText: "",
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'company')
            }
        },
        state:{
            value: "",
            error: false,
            helperText: "",
            validation: (value) => {
                return validateNotNullInput(value, setFormValues, 'state')
            }
        }
    })

    const baseEndpoint = "/company-payment-method-informations"

    useEffect(() => {
        getCall("/apis-page-data/all", setPageData, setPageSubmitResponse, undefined)
    }, [])

    const columns = entities[0]?.phonenumber !== null ? [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'phonenumber', headerName: 'Numéro', width: 130 },
        { field: 'companyName', headerName: 'Société', width: 130 },
        { field: 'stateName', headerName: 'Statut', width: 130 },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, true) }}>Modifier</Button>
            ),
        },
        // see more link with params.id
        {
            field: 'more',
            headerName: 'Plus',
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" color="primary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, false) }}>Détails</Button>
            ),
        }
    ] : [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'pan', headerName: 'Numéro de carte', width: 130 },
        { field: 'expiryMonth', headerName: 'Mois d\'expiration', width: 130 },
        { field: 'expiryYear', headerName: 'Année d\'expiration', width: 130 },
        { field: 'securityCode', headerName: 'Code de sécurité', width: 130 },
        { field: 'companyName', headerName: 'Société', width: 130 },
        { field: 'stateName', headerName: 'Statut', width: 130 },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, true) }}>Modifier</Button>
            ),
        },
        // see more link with params.id
        {
            field: 'more',
            headerName: 'Plus',
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" color="primary" onClick={(e) => { e.stopPropagation(); handleUpdateClick(params, false) }}>Détail</Button>
            ),
        }
    ];

    const buildEntity = (formValues) => {
        return {
            phonenumber: formValues.phonenumber.value===""?null:formValues.phonenumber.value,
            pan: formValues.pan.value===""?null:formValues.pan.value,
            expiryMonth: formValues.expiryMonth.value===""?null:formValues.expiryMonth.value,
            expiryYear: formValues.expiryYear.value===""?null:formValues.expiryYear.value,
            securityCode: formValues.securityCode.value===""?null:formValues.securityCode.value,
            company: {
                id: formValues.company.value
            },
            paymentMethod: {
                id: parseInt(currentPaymentMethodId)
            },
            state: {
                id: formValues.state.value
            }
        }
    }


    const handleAddEntity = (e) => {
        // execute validation in formValues
        const success = validateFormValues(formValues)
        if (success) {
            const entity = buildEntity(formValues)
            postCall(`${baseEndpoint}`, entity, resetFormValues, formValues, setFormValues, setSubmitResponse, handleCloseAddDialog)
            setTimeout(() => {
                getCompanyPaymentMethodInformations(currentPaymentMethodId)
            }, 500);
        }
    }

    const handleUpdateEntity = (e) => {
        const success = validateFormValues(formValues)
        if (success) {
            let currentEntity = buildEntity(formValues)
            currentEntity.id = currentUserId
            putCall(`${baseEndpoint}`, currentEntity, setSubmitResponse)
            setTimeout(() => {
                getCompanyPaymentMethodInformations(currentPaymentMethodId)
            }, 500);
        }
    }

    const handleUpdateClick = (params, update) => {
        resetFormErrors(formValues)
        setSubmitResponse(false)
        // mock a fake user
        axios.get(`${BASE_URL + baseEndpoint}/${params.id}`, getHeaders()).then((response) => {
            const entity = response.data.data
            console.log(entity)
            setCurrentUserId(entity.id)
            if (!update) {
                setUpdateButton(false)
                // set formValues default value
                setToDisabled(formValues, setFormValues, entity, true)
            } else {
                setUpdateButton(true)
                // set formValues default value
                setToDisabled(formValues, setFormValues, entity, false)
            }
            setFormTitle(update ? "Modifier a " + labelInfo : "Info of a " + labelInfo)
            setAddDialogOpen(true);
        }
        ).catch((error) => {
            setSubmitResponse({ severity: "error", message: error.response.data.message })
        }
        )
    };

    const handleConfirmDelete = () => {
        // Perform delete logic
        axios.post(`${BASE_URL + baseEndpoint}/multiple-delete`, selectedIds, getHeaders()).then((response) => {
            setPageSubmitResponse({ severity: "success", message: `${labelInfo}s deleted successfully` })
            getCompanyPaymentMethodInformations(currentPaymentMethodId)
        }
        ).catch((error) => {
            setPageSubmitResponse({ severity: "error", message: error.response.data.message })
        }
        ).finally(() => {
            setDialogOpen(false);
        }).then(() => {
            setTimeout(() => {
                setPageSubmitResponse(false)
            }, 3000)
        })
        // Close the dialog
    };

    // not to be touched anymore

    // done
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
            },
        }));
    }

    // done
    const handleSelectionChange = (selectionModel) => {
        // replace existing selectedIds with selectionModel
        selectionModel.length !== 0 ? setShowDelete(true) : setShowDelete(false)
        setSelectedIds(selectionModel);
    };

    // done
    const handleDeleteClick = () => {
        setDialogOpen(true); // Open the dialog
    };

    // done
    const handleCloseDialog = () => {
        // Close the dialog without performing delete
        setDialogOpen(false);
    };

    // done
    const handleAddClick = () => {
        setSubmitResponse(false)
        setUpdateButton(true)
        setFormTitle("Ajouter a " + labelInfo)
        // set formValues error to false
        resetFormErrors(formValues)
        resetFormValues(formValues, setFormValues)
        setAddDialogOpen(true);
    };

    // done
    const handleCloseAddDialog = () => {
        setAddDialogOpen(false);
    };

    return (
        <div style={{ height: '70vh', width: '100%', backgroundColor: 'white' }}>
            {/* add and delete button group */}
            <AddDeleteButtonGroup handleAddClick={handleAddClick} handleDeleteClick={handleDeleteClick} showDelete={showDelete} />
            {
                pageSubmitResponse && <Alert severity={pageSubmitResponse.severity}>{pageSubmitResponse.message}</Alert>
            }
            {/* datatable using datagrid */}
            <h3 style={{ textAlign: "center" }}>{title}</h3>
            <DataGrid
                rows={entities}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={getRowClassName}
                onRowSelectionModelChange={(item) => handleSelectionChange(item)}
                checkboxSelection
            />
            {/* dialog for delete items */}
            <ConfirmDialog dialogOpen={dialogOpen} handleCloseDialog={handleCloseDialog} message={`Are you sure you want to delete those ${selectedIds.length} item(s)?`} handleConfirm={handleConfirmDelete} />
            {/* Ajouter dialog */}
            <Dialog open={addDialogOpen} onClose={handleCloseAddDialog}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        '& .MuiFormControl-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    style={{ padding: "35px" }}
                >
                    {
                        submitResponse && <Alert severity={submitResponse.severity}>{submitResponse.message}</Alert>
                    }
                    <h2 id="user-form">{formTitle}</h2>

                    {
                        entities[0]?.phonenumber !== null ? <div><CustomInput formValues={formValues} handleChange={handleChange} title={"Phone number"} label={"phonenumber"} required={true} /><CustomSelect formValues={formValues} handleChange={handleChange} items={pageData.states} label={"state"} /></div> :
                            <>
                                <div>
                                    <CustomInput formValues={formValues} handleChange={handleChange} label={"pan"} required={true} />
                                    <CustomInput formValues={formValues} handleChange={handleChange} title={"Expiry month"} label={"expiryMonth"} required={true} />
                                </div>
                                <div>
                                    <CustomInput formValues={formValues} handleChange={handleChange} title={"Expiry year"} label={"expiryYear"} required={true} />
                                    <CustomInput formValues={formValues} handleChange={handleChange} title={"Security code"} label={"securityCode"} required={true} />
                                </div>
                                <div>
                                <CustomSelect formValues={formValues} handleChange={handleChange} items={pageData.states} label={"state"} />
                                </div>
                                
                            </>
                    }

                    <div>
                        <CustomSelect formValues={formValues} handleChange={handleChange} items={pageData.company} label={"company"} />
                    </div>
                    <br />
                    <div style={{ width: "100%" }}>
                        {
                            updateButton && <Button onClick={formTitle === `Ajouter a ${labelInfo}` ? handleAddEntity : handleUpdateEntity} variant="contained" color="primary">
                                {formTitle === `Ajouter a ${labelInfo}` ? "Ajouter" : "Modifier"}
                            </Button>
                        }
                    </div>
                </Box>
            </Dialog>
        </div>
    )
}

export default CompanyApiPaymentMethodInformation